import React from 'react';
import './App.css';
import {Badge, Button, Image, Layout, Menu} from "antd";
import {Header} from "antd/es/layout/layout";
import MenuItem from "antd/es/menu/MenuItem";
import Search from "antd/es/input/Search";
import {SearchProps} from "antd/lib/input";
import Form from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";
import Avatar from "antd/es/avatar/avatar";

const menuItems = [
    {key: 1, label: '首页'},
    {key: 2, label: '知乎知学堂'},
    {key: 3, label: '发现'},
    {key: 4, label: '等你来答'}
]

const onSearch: SearchProps['onSearch'] = (value, _e, info) => console.log(info?.source, value);

function App() {
  return (
      <Layout>
          <Header className='App-header'>
              <a href='http://localhost:3000' className='logo'></a>
              <Menu className={'header-nav'} mode={'horizontal'}>
                   {menuItems.map(item => (
                       <MenuItem key={item.key}>{item.label}</MenuItem>
                   ))}
              </Menu>
              <div style={{display: 'flex'}}>
                  <Form layout={"inline"}>
                      <FormItem>
                          <Search onSearch={onSearch} placeholder={'搜索你感兴趣的内容'} enterButton={true} />
                      </FormItem>
                  </Form>
              </div>
              <div className={'AppHeader-user'}>
                  <Badge dot>
                    <Avatar src={<img src={`${process.env.PUBLIC_URL}/img.png`} alt="avatar" />} />
                  </Badge>
              </div>
          </Header>
      </Layout>
  );
}

export default App;